import React, { memo } from "react"
import styled from "utils/styled"
import { rhythm } from "utils/typography"
import { PrismAsyncLight } from "react-syntax-highlighter"
import { twilight as prismStyle } from "react-syntax-highlighter/dist/esm/styles/prism"

export const Well = memo(styled.div`
  padding: ${p => p.theme.space[3]};
  background: ${p => p.theme.colors.palette.grey[20]};
  border-radius: ${p => p.theme.radii[2]};
  box-shadow: inset 0 0 2px black, 0 0 2px black;
  margin-bottom: ${p => rhythm(p.theme.typography.options.blockMarginBottom)};
`)

const CodeContainer = styled.div`
  margin-bottom: ${p => rhythm(p.theme.typography.options.blockMarginBottom)};
`

export const SyntaxHighlighter = memo(({ children, ...restProps }: any) => (
  <CodeContainer>
    <PrismAsyncLight style={prismStyle} {...restProps}>
      {children}
    </PrismAsyncLight>
  </CodeContainer>
))

export const InlineCode = memo(styled.span`
  font-family: Consolas, Menlo, Monaco, Andale Mono WT, Andale Mono, Lucida Console, Lucida Sans Typewriter,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Liberation Mono, Nimbus Mono L, Courier New, Courier, monospace;
  overflow-wrap: anywhere;
  overflow: auto;
  overflow-x: visible;
  overflow-y: visible;
  white-space: normal;
  padding: ${p => p.theme.space[1]};
  background: ${p => p.theme.colors.palette.blue[10]};
  border-radius: ${p => p.theme.radii[2]};
`)
