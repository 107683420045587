/** @jsx jsx */
import { jsx } from "theme-ui"
import { PageProps } from "gatsby"

import StateLink, { LocationState } from "components/state-link"
import Layout from "components/layout"
import SEO from "components/seo"
import RelatedContent from "components/related-content"
import { SyntaxHighlighter, InlineCode } from "components/article-elements"
import ArticleImage from "components/article-image"
import { getLocationState, makeLocationState } from "components/state-link"
import { Location as HLocation } from "history"

const Page = ({ location }: PageProps) => {
  const title = `Формула расчета ипотеки`
  const description = `Формула для самостоятельного расчета ипотечного кредита. Расчет аннуитетных и дифференцированных платежей.`
  const locationState = getLocationState((location as any) as HLocation<LocationState>)

  const annuityExampleLocationState = makeLocationState("/", `?interestRate=9.6&price=2000000&downPayment=500000`)
  const differentialExampleLocationState = makeLocationState(
    "/",
    `?interestRate=9.6&price=2000000&downPayment=500000&isAnnuity=false`
  )
  const calcLink = `/#calculator`

  return (
    <Layout locationState={locationState}>
      <SEO title={title} description={description} pathname={location.pathname} />
      <h1 id="calculator">Формулы для самостоятельного расчета ипотеки</h1>
      <div>
        <hr />
      </div>
      <div id="content">
        <h2>Два вида ипотечных платежей</h2>
        <p>
          Ипотечные платежи бывают{" "}
          <StateLink state={locationState} to="/формула-расчета-ипотеки/#annuity-mortgage-calculation">
            аннуитетными
          </StateLink>{" "}
          и{" "}
          <StateLink state={locationState} to="/формула-расчета-ипотеки/#differential-mortgage-calculation">
            дифференцированными
          </StateLink>
          . При аннуитетных платежах размер ежемесячного платежа неизменен на протяжении всей ипотеки. Размер
          ежемесячных платежей при дифференцированной схеме постоянно уменьшается. Аннуитетные платежи более
          распространены, чем дифференцированные. Читайте подробнее про{" "}
          <StateLink state={locationState} to="/#annuity-vs-differential">
            аннуитетные и дифференцированные платежи
          </StateLink>
          .
        </p>
        <h2 id="annuity-mortgage-calculation">Расчет ипотеки для аннуитетных платежей</h2>
        <h3 id="monthly-percent">Ежемесячная процентная ставка</h3>
        <p>Для начала нам понадобится рассчитать ежемесячную процентную ставку по кредиту:</p>
        <SyntaxHighlighter language="python">
          ЕЖЕМЕСЯЧНАЯ_СТАВКА = ПРОЦЕНТНАЯ_СТАВКА_ГОДОВЫХ / 12 / 100
        </SyntaxHighlighter>
        <p>
          Для процентной ставки <InlineCode>9.6%</InlineCode> годовых:
        </p>
        <SyntaxHighlighter language="python">ЕЖЕМЕСЯЧНАЯ_СТАВКА = 9.6 / 12 / 100 = 0.008</SyntaxHighlighter>
        <h3>Ежемесячный платеж</h3>
        <p>
          Мы должны определить для себя{" "}
          <StateLink state={locationState} to="/#credit-sum">
            сумму ипотечного кредита
          </StateLink>{" "}
          в рублях и{" "}
          <StateLink state={locationState} to="/#credit-term">
            срок кредитования
          </StateLink>{" "}
          в месяцах. Рассчитаем ежемесячный платеж по ипотеке:
        </p>
        <SyntaxHighlighter language="python">
          {`ОБЩАЯ_СТАВКА = (1 + ЕЖЕМЕСЯЧНАЯ_СТАВКА) ^ СРОК_ИПОТЕКИ_МЕСЯЦЕВ\n` +
            `ЕЖЕМЕСЯЧНЫЙ_ПЛАТЕЖ = СУММА_КРЕДИТА * ЕЖЕМЕСЯЧНАЯ_СТАВКА * ОБЩАЯ_СТАВКА / (ОБЩАЯ_СТАВКА - 1)`}
        </SyntaxHighlighter>
        <p>
          Крышка (<InlineCode>^</InlineCode>) - это возведение в степень. Рассмотрим пример: берем в ипотеку на{" "}
          <InlineCode>20 лет</InlineCode>
          квартиру стоимостью <InlineCode>2 000 000 руб</InlineCode>, а первоначальный взнос равен{" "}
          <InlineCode>500 000 руб</InlineCode>. Сумма кредита равна <InlineCode>1 500 000 руб</InlineCode>, процентная
          ставка остается из примера выше - <InlineCode>9.6%</InlineCode> годовых. Рассчитаем ежемесячный платеж:
        </p>
        <SyntaxHighlighter language="python">
          {`ОБЩАЯ_СТАВКА = (1 + 0.008) ^ (20 лет * 12 (месяцев / год)) = 6.77\n` +
            `ЕЖЕМЕСЯЧНЫЙ_ПЛАТЕЖ = 1 500 000 руб * 0.008 * 6.77 / (6.77 - 1) = 14 080 руб`}
        </SyntaxHighlighter>
        <h3>Основной долг и проценты</h3>
        <p>
          Мы вычислили размер ежемесячного платежа по ипотеке при аннуитетной схеме платежей. Рассчитаем какая часть
          платежа идет на погашение основного долга, а какая на погашение процентов:
        </p>
        <SyntaxHighlighter language="python">
          {`ПРОЦЕНТНАЯ_ЧАСТЬ = ОСТАТОК_ДОЛГА * ЕЖЕМЕСЯЧНАЯ_СТАВКА\n` +
            `ОСНОВНАЯ_ЧАСТЬ = ЕЖЕМЕСЯЧНЫЙ_ПЛАТЕЖ - ПРОЦЕНТНАЯ_ЧАСТЬ`}
        </SyntaxHighlighter>
        <p>
          Эти величины меняются с каждым платежем, так как меняется остаток долга. Рассмотрим первый платеж для ипотеки
          из примера выше:
        </p>
        <SyntaxHighlighter language="python">
          {`ПРОЦЕНТНАЯ_ЧАСТЬ = 1 500 000 руб * 0.008 = 12 000 руб\n` +
            `ОСНОВНАЯ_ЧАСТЬ = 14 080 руб - 12 000 руб = 2 080 руб`}
        </SyntaxHighlighter>
        <h3>Переплата</h3>
        <p>Размер переплаты за кредит рассчитывается так:</p>
        <SyntaxHighlighter language="python">
          {`ПЕРЕПЛАТА = ЕЖЕМЕСЯЧНЫЙ_ПЛАТЕЖ * СРОК_ИПОТЕКИ_МЕСЯЦЕВ - СУММА_КРЕДИТА`}
        </SyntaxHighlighter>
        <p>Для ипотеки из примера выше:</p>
        <SyntaxHighlighter language="python">
          {`ПЕРЕПЛАТА = 14 080 руб * (20 лет * 12 (месяцев / год)) - 1 500 000 руб = 1 879 200 руб`}
        </SyntaxHighlighter>
        <h3 id="annuity-chart">График аннуитетных платежей</h3>
        <ArticleImage
          src="annuity_chart.png"
          alt="График аннуитетных ипотечных платежей для рассматриваемого примера"
        />
        <ArticleImage
          src="annuity_payments_table.png"
          alt="Таблица аннуитетных ипотечных платежей для рассматриваемого примера"
        />
        <p>
          На рисунках - график и таблица платежей для рассматриваемого примера. Их построил{" "}
          <StateLink state={annuityExampleLocationState} to={calcLink}>
            наш ипотечный калькулятор
          </StateLink>
          {` (ссылка ведет на расчет рассматриваемого примера).`}
        </p>
        <p>
          Чтобы рассчитать их надо провести серию вычислений в количестве, равном кол-ву ипотечных платежей (месяцев
          кредита). Для каждого месяца ипотеки надо пересчитывать текущий остаток долга. В псевдокоде это можно записать
          так:
        </p>
        <SyntaxHighlighter language="python">
          {[
            `ЕЖЕМЕСЯЧНАЯ_СТАВКА = ПРОЦЕНТНАЯ_СТАВКА_ГОДОВЫХ / 12 / 100`,
            `ОБЩАЯ_СТАВКА = (1 + ЕЖЕМЕСЯЧНАЯ_СТАВКА) ^ СРОК_ИПОТЕКИ_МЕСЯЦЕВ`,
            `ЕЖЕМЕСЯЧНЫЙ_ПЛАТЕЖ = СУММА_КРЕДИТА * ЕЖЕМЕСЯЧНАЯ_СТАВКА * ОБЩАЯ_СТАВКА / (ОБЩАЯ_СТАВКА - 1)`,
            `ОСТАТОК_ДОЛГА = СУММА_КРЕДИТА`,
            ``,
            `в цикле для каждого ТЕКУЩИЙ_МЕСЯЦ в ипотеке:`,
            `    ПРОЦЕНТНАЯ_ЧАСТЬ = ОСТАТОК_ДОЛГА * ЕЖЕМЕСЯЧНАЯ_СТАВКА`,
            `    ОСНОВНАЯ_ЧАСТЬ = ЕЖЕМЕСЯЧНЫЙ_ПЛАТЕЖ - ПРОЦЕНТНАЯ_ЧАСТЬ`,
            `    ОСТАТОК_ДОЛГА = ОСТАТОК_ДОЛГА - ОСНОВНАЯ_ЧАСТЬ`,
            `    добавить (ТЕКУЩИЙ_МЕСЯЦ; ПРОЦЕНТНАЯ_ЧАСТЬ; ОСНОВНАЯ_ЧАСТЬ) на график`,
          ].join(`\n`)}
        </SyntaxHighlighter>
        <h3>Расчет ипотеки калькулятором</h3>
        <p>
          Намного проще воспользоваться готовым{" "}
          <StateLink state={annuityExampleLocationState} to={calcLink}>
            ипотечным калькулятором
          </StateLink>
          , который сам рассчитает ипотеку по вышеприведенным формулам. Это будет значительно быстрее, чем считать это
          самостоятельно :)
        </p>
        <p>
          Мы подготовили{" "}
          <a
            href="https://docs.google.com/spreadsheets/d/1f0SlHWvqlzYVBWp35Bsp2r6XV_Ck3o-P4NtJtbuiY3s/edit?usp=sharing"
            target="_blank"
          >
            Google Таблицу
          </a>{" "}
          для самостоятельного расчета аннуитетного платежа. Редактирование: <i>{"Файл -> Создать копию"}</i>.
        </p>
        <h2 id="differential-mortgage-calculation">Расчет ипотеки для дифференцированных платежей</h2>
        <h3>Ежемесячный процент</h3>
        <p>
          Ежемесячный платеж считается так же, как и{" "}
          <StateLink state={locationState} to="/формула-расчета-ипотеки/#monthly-percent">
            ежемесячный процент аннуитетных платежей
          </StateLink>
          .
        </p>
        <h3>Ежемесячный платеж</h3>
        <p>
          При дифференцированных платежах основной долг гасится равномерно в течение всего срока кредитования.
          Рассчитаем долю ежемесячного платежа, идущую на погашение основного долга:
        </p>
        <SyntaxHighlighter language="python">
          ЕЖЕМЕСЯЧНОЕ_ПОГАШЕНИЕ_ДОЛГА = СУММА_КРЕДИТА / СРОК_ИПОТЕКИ_МЕСЯЦЕВ
        </SyntaxHighlighter>
        <p>
          Если мы берем ипотечный кредит на сумму <InlineCode>1 500 000 руб</InlineCode> на{" "}
          <InlineCode>20 лет</InlineCode>, то
        </p>
        <SyntaxHighlighter language="python">
          ЕЖЕМЕСЯЧНОЕ_ПОГАШЕНИЕ_ДОЛГА = 1 500 000 руб / (20 лет * 12 месяцев / год) = 6 250 руб
        </SyntaxHighlighter>
        <p>Ежемесячное погашение процентов считается так же, как и для аннуитетных платежей:</p>
        <SyntaxHighlighter language="python">
          {`ПРОЦЕНТНАЯ_ЧАСТЬ = ОСТАТОК_ДОЛГА * ЕЖЕМЕСЯЧНАЯ_СТАВКА`}
        </SyntaxHighlighter>
        <p>
          Итого ежемесячный платеж это сумма процентной части и части на погашение основного долга. Эта сумма каждый
          месяц пересчитывается и уменьшается, так как размер основного долга уменьшается.
        </p>
        <p>Для первого платежа из рассматриваемого примера это будет:</p>
        <SyntaxHighlighter language="python">
          {`ПОГАШЕНИЕ_ДОЛГА = 1 500 000 руб / (20 лет * 12 месяцев / год) = 6 250 руб\n` +
            `ПРОЦЕНТНАЯ_ЧАСТЬ = 1 500 000 руб * 0.008 = 12 000 руб\n` +
            `ПЛАТЕЖ_ЗА_МЕСЯЦ = 6 250 руб + 12 000 руб = 18 250 руб`}
        </SyntaxHighlighter>
        <p>
          Обратите внимание, что в начале кредита ежемесячный платеж при дифференцированной схеме (
          <InlineCode>18 250 руб</InlineCode>) больше, чем аннуитетной схеме (<InlineCode>14 080 руб</InlineCode>). Но
          при этом чуть бОльшая (<InlineCode>6 250 руб</InlineCode> против <InlineCode>2 080 руб</InlineCode>) часть
          платежа идет на погашение долга.
        </p>
        <h3>График дифференцированных платежей</h3>
        <ArticleImage
          src="differential_chart.png"
          alt="График дифференцированных ипотечных платежей для рассматриваемого примера"
        />
        <ArticleImage
          src="differential_payments_table.png"
          alt="Таблица дифференцированных ипотечных платежей для рассматриваемого примера"
        />
        <p>
          На рисунках - график и таблица дифференцированных платежей для рассматриваемого примера. Их построил{" "}
          <StateLink state={differentialExampleLocationState} to={calcLink}>
            наш ипотечный калькулятор
          </StateLink>
          {` (ссылка ведет на расчет рассматриваемого примера).`}
        </p>
        <p>
          Они рассчитывается аналогично{" "}
          <StateLink state={locationState} to="/формула-расчета-ипотеки/#annuity-chart">
            аннуитетным платежам
          </StateLink>
          .
        </p>
      </div>
      <RelatedContent locationState={locationState} />
    </Layout>
  )
}

export default Page
