/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

import Image from "./image"

const ArticleImage = React.memo(({ src, alt, caption }: any) => (
  <div>
    <figure sx={{ maxHeight: "100%", height: "300px", mb: 6 }}>
      <Image style={{ maxHeight: "100%" }} imgStyle={{ objectFit: "contain" }} src={src} alt={alt} />
      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  </div>
))

export default ArticleImage
